/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
/* eslint-disable no-undef*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import Footer from './footer';
import { getUser } from '../../api/User';
import { getAuthToken } from '../../utilities/helpers';
import { CONTACT_NAME, CONTACT_EMAIL } from '../../utilities/constants';

import '../../styles/layout.css';

const DISCOVERY_DOCS = ['https://sheets.googleapis.com/$discovery/rest?version=v4'];
const Layout = ({ children }) => {
  const [token, setToken] = useState(Cookies.get('GoogleToken'));

  if (typeof document !== 'undefined') {
    document.onvisibilitychange = function() {
      if (document.visibilityState === 'visible') {
        if (Cookies.get('UserEmail') && !Cookies.get('GoogleToken')) {
          getAuthToken();
        }
      }
    };
  }

  useEffect(() => {
    const initializeGapiClient = async () => {
      try {
        await gapi.client.init({
          apiKey: `${process.env.GOOGLE_API_KEY}`,
          discoveryDocs: DISCOVERY_DOCS,
        });
      } catch (error) {
        console.warn(error);
      }
    };

    gapi.load('client', initializeGapiClient);
  }, [token]);

  useEffect(() => {
    const getUserEmail = async () => {
      try {
        const userResponse = await getUser();
        const userEmail = userResponse.data;
        Cookies.set('UserEmail', userEmail);
      } catch (error) {
        console.warn(error);
      }
    };
    if (token) {
      getUserEmail();
    }
  }, [token]);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  function handleSignInClick(event) {
    getAuthToken(setToken);
  }

  function handleSignOutClick(event) {
    if (token !== null) {
      google.accounts.oauth2.revoke(token);
      gapi.client.setToken('');
      setToken('');
    }
    Cookies.remove('GoogleToken');
    Cookies.remove('TokenActive');
    Cookies.remove('UserEmail');
  }

  if (!token) {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <img className="navBar-img" src="/images/numerator-logo.png" alt="Numerator" />
        <h1>HACKDAY PORTAL</h1>
        <button id="authorize_button" onClick={() => handleSignInClick()}>
          Sign in with Google
        </button>
        <p
          style={{
            fontSize: '12px',
            maxWidth: '30%',
            paddingTop: '30px',
            textAlign: 'center',
          }}
        >
          You will be asked to consent to addititional access to your information and spreadsheets.
          Only users within the Numerator Group can update the hackday website spreadsheet, which is
          why we require you to log in and allow access to update it on your behalf. <br /> We do
          not access any other spreadsheets.
          <br />
          <br /> If you have any questions or concerns, please reach out to <a href={`mailto:${CONTACT_EMAIL}`}>{ CONTACT_NAME }</a>.
        </p>
      </div>
    );
  }

  if (token) {
    return (
      <>
        <Header siteTitle={data.site.siteMetadata.title} handleSignOutClick={handleSignOutClick} />
        <div className="hackdaysite-container">
          <main id={'children-container'}>{children}</main>
        </div>
        <Footer />
      </>
    );
  }
  return null;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
